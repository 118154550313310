import { render, staticRenderFns } from "./Html.vue?vue&type=template&id=b8bb9096&scoped=true&"
import script from "./Html.vue?vue&type=script&lang=js&"
export * from "./Html.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8bb9096",
  null
  
)

export default component.exports